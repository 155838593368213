import React, { Component } from 'react';
import addToMailchimp from "gatsby-plugin-mailchimp"


class FooterSubscribeForm extends Component {
	constructor(props){
		super(props);
		this.state = {
            subscriberEmail: "",
            message: ""
		}
	}

handleChange = (event) => {
    this.setState({ 
        subscriberEmail: event.target.value 
    });
}

handleSubmit = async (event) => {
   event.preventDefault();
   const result = await addToMailchimp(this.state.subscriberEmail);
   if(result.result == "success"){
        this.setState({message: result.msg}); 
   }else if(result.result == "error"){
        this.setState({message: "You're already a subscriber."})
   }
   
   console.log(result);
 }


render () {
return (
    
    <form className="mt-4 sm:flex sm:max-w-md">

        <div>
            {this.state.message ? 
            <h5 className="text-white mb-4">{this.state.message}</h5> :  
            <input onChange={this.handleChange} name="subscriberEmail" type="email" className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400" placeholder="Email" aria-describedby="emailSubscribe"/> 
            }
        </div>

        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button onClick={this.handleSubmit} className="w-full bg-blue-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">Submit</button>
        </div>

    </form>
    
 )
 }
}

export default FooterSubscribeForm;