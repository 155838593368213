import * as React from "react";
import { useState } from "react";
import { Transition } from '@headlessui/react';
import { Link } from "gatsby";
import HealthOneLogo from '../images/HealthOneLogo.png';

const Nav = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [toggleMobileNav, setToggleMobileNav] = useState(false);


    return (
    <div className="relative bg-white shadow">
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">

        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link to="/">
            <span className="sr-only">Health One Digital</span>
            <img className="h-12 w-auto" src={HealthOneLogo} alt="Health One Digital Logo"/>
            </Link>
        </div>

        <div className="-mr-2 -my-2 md:hidden">
          <button type="button" onClick={() => setToggleMobileNav(true)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        <nav className="hidden md:flex space-x-10">
          <Link to="/" className="text-base font-medium text-blue-900 hover:text-gray-600 tracking-wide">
            Home
          </Link>
          <div className="relative">

            <button type="button" onMouseEnter={() => setIsOpen(!isOpen)} className="group bg-white rounded-md text-blue-900 inline-flex tracking-wide items-center text-base font-medium hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span>Our Servcies</span>
              <svg className="ml-2 h-5 w-5 text-blue-900 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>

            <Transition appear={true} show={isOpen} enter="transition-opacity duration-75" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div onMouseLeave={() => setIsOpen(false)}  className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">

                          {/* <Link to="/services" className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-100">
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                    Services Overview
                                </p>
                              </div>
                          </Link> */}

                            <Link to="/services/website-design" className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-100">
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                      Website Design & Development
                                  </p>
                                </div>
                            </Link>

                            <Link to="/services/digital-marketing" className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-100">
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                      Digital Marketing
                                  </p>
                                </div>
                            </Link>

                            <Link to="/services/branding-design" className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-100">
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                      Branding Design
                                  </p>
                                </div>
                            </Link>

                            {/* <Link to="/services/web-app-development" className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-100">
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                      Web Application Development
                                  </p>
                                </div>
                            </Link> */}
                        </div>
                    </div>
                </div> 
            </Transition>
          </div>

          <Link to="/our-work" className="text-base font-medium text-blue-900 hover:text-gray-600 tracking-wide">
            Our Work
          </Link>

         {/* <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
           Our Insights
          </a> */}

          
          <Link to="/about-us" className="text-base font-medium text-blue-900 hover:text-gray-600 tracking-wide">
            About Us
          </Link>
        
        </nav>

        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <Link to="/contact-us" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-6 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-800 hover:bg-blue-600">
            Contact Us
          </Link>
        </div>

      </div>
    </div>

    {/* MOBILE MENU */}
    { toggleMobileNav ?

      <div className="absolute top-0 z-10 inset-x-0 p-2 transition transform origin-top-right md:hidden">
        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="px-5 pt-4 flex items-center justify-between">
            <div className="-mr-2">
              <button type="button" onClick={() => setToggleMobileNav(false)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>

          <Transition show={toggleMobileNav} enter="duration-500 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95"> 
          <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
            <div className="px-2 pt-2 pb-3 relative z-100" role="none">
          
              <Link to="/" className="block px-3 py-2 rounded-md text-base font-bold text-blue-900 hover:text-gray-600 tracking-wide">Home</Link>

              <p className="block px-3 py-2 rounded-md text-base font-bold text-blue-900 tracking-wide">Services</p>

              <Link to="/services/website-design" className="block px-3 py-2 ml-5 rounded-md text-base font-medium text-blue-900 hover:text-gray-600 tracking-wide">Website Design</Link>

              <Link to="/services/digital-marketing" className="block px-3 py-2 ml-5 rounded-md text-base font-medium text-blue-900 hover:text-gray-600 tracking-wide">Digital Marketing</Link>

              <Link to="/services/branding-design" className="block px-3 py-2 ml-5 rounded-md text-base font-medium text-blue-900 hover:text-gray-600 tracking-wide">Branding Design</Link>

              {/* <Link to="/services/web-app-development" className="block px-3 py-2 ml-5 rounded-md text-base font-medium text-blue-900 hover:text-gray-600 tracking-wide">Web App Development</Link> */}

              <Link to="/our-work" className="block px-3 py-2 rounded-md text-base font-bold text-blue-900 hover:text-gray-600 tracking-wide">Our Work</Link>

              <Link to="/about-us" className="block px-3 py-2 rounded-md text-base font-bold text-blue-900 hover:text-gray-600 tracking-wide">About Us</Link>

              <Link to="/contact-us" className="block px-3 py-2 rounded-md text-base font-bold text-blue-900 hover:text-gray-600 tracking-wide">Contact Us</Link>

            </div>
          </div>
          </Transition>
        </div>
      </div> 
      : "" }
    </div>
    )
}

export default Nav;